import { ButtonProps, LinkButtonSettings } from '@almbrand/button';
import { CtaGroup } from '@almbrand/ctagroup';
import { RichText } from '@almbrand/richtext';
import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { ctaGroupMargins } from 'utilities';
import './ParagraphComponent.scss';

export interface ParagraphComponentProps extends LocalId {
	//	countdownPositionTop: boolean;
	//	header: string;
	richText: string;
	contentText?: string; // hidden text
	padding?: string;
	backgroundColor?: BackgroundColor; // TODO: map in component library
	buttons?: Button[];
	ctaMarginTop?: string;
	ctaMarginBottom?: string;
	contentId?: number | string;
	//	trackAsPromotion: boolean; // TODO: how can we use this one
}

export const ParagraphComponent: React.FC<ParagraphComponentProps> = ({
	contentId,
	backgroundColor,
	padding,
	richText,
	contentText,
	buttons,
	ctaMarginTop,
	ctaMarginBottom,
	useReadline,
	readlineId,
}) => {
	const [isContentTextVisible, setIsContentTextVisible] = useState(false);
	const contentRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (contentRef.current) {
			if (isContentTextVisible) {
				contentRef.current.style.maxHeight = `${contentRef.current.scrollHeight}px`;
			} else {
				contentRef.current.style.maxHeight = '0';
			}
		}
	}, [isContentTextVisible]);

	const toggleContentTextVisibility = () => {
		setIsContentTextVisible(!isContentTextVisible);
	};

	const mapButtonsToCtaGroup = (buttons: Button[]): ButtonProps[] =>
		buttons.map(({ title = '', type, color, link, target }) => ({
			children: title,
			buttonType: (type === 'cta' ? color : type) as ButtonProps['buttonType'],
			linkButtonSettings: {
				title,
				href: link?.url,
				target: target as LinkButtonSettings['target'],
			},

			trackingEvent: {
				event: 'trackNavigation',
				navigationAction: title ?? '',
				navigationDestination: link?.url ?? '',
				navigationType: 'call-to-action',
			},
		}));

	return (
		<div
			className={classNames('paragraph', backgroundColor?.cssClass, `p-${padding}`)}
			style={
				{
					'--blockBgColor': !isContentTextVisible && contentText ? backgroundColor?.colorHex : '',
				} as React.CSSProperties
			}
		>
			<div className={!isContentTextVisible && contentText ? 'paragraph__add-fade' : undefined}>
				<RichText id={useReadline && readlineId ? readlineId : contentId} description={richText} />
			</div>
			{buttons && (
				<CtaGroup
					ctaGroup={mapButtonsToCtaGroup(buttons)}
					className={ctaGroupMargins(ctaMarginTop, ctaMarginBottom)}
				/>
			)}
			{/* Hidden text */}
			{isContentTextVisible && contentText && (
				<div
					className="paragraph__contentText"
					ref={contentRef}
					style={{
						maxHeight: isContentTextVisible ? `${contentRef.current?.scrollHeight}px` : '0',
					}}
				>
					<RichText description={contentText} />
				</div>
			)}

			{contentText && (
				<div
					className={classNames(
						'paragraph__contentVisible--triggerWrapper',
						isContentTextVisible ? 'paragraph__contentVisible--active' : undefined,
					)}
				>
					<hr />
					<button onClick={toggleContentTextVisibility}>
						<div className="paragraph__contentVisible--triggerIcon">
							<span></span>
							<span></span>
						</div>
					</button>
					<hr />
				</div>
			)}
		</div>
	);
};
